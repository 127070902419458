<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Задачи' }]" />
      <h4 class="page-title">Задачи</h4>
    </div>
    <GridView
      :service="service"
      :actions="[
        {
          name: 'create',
          routeName: 'TaskCreate',
        },
      ]"
      :export="false"
      :columns="[
        {
          field: 'name',
          header: 'Название',
          sortable: true,
          slot: 'nameSlot',
          filter: {
            label: 'Название',
            field: 'name',
            type: 'text',
          },
        },
        {
          field: 'status',
          header: 'Статус',
          sortable: true,
          slot: 'statusSlot',
          filter: {
            label: 'Статус',
            field: 'status',
            type: 'multiselect',
            options: [
              {
                id: 0,
                name: 'Не выполнена',
              },
              {
                id: 1,
                name: 'Выполняется',
              },
              {
                id: 2,
                name: 'Выполнена',
              },
            ],
          },
        },
        {
          field: 'start',
          header: 'Срок',
          slot: 'twoDate',
          sortable: true,
        },
        {
          field: 'producer',
          header: 'Постановщик',
          slot: 'producerName',
          filter: {
            label: 'Постановщик',
            field: 'producer',
            type: 'multiselect',
            options: userList,
          },
        },
        {
          actions: [
            {
              action: 'show',
              routeName: 'TaskShow',
            },
            {
              action: 'edit',
              routeName: 'TaskEdit',
            },
            {
              action: 'delete',
            },
          ],
        },
      ]"
      :search="[
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'Постановщик',
          field: 'producer',
          type: 'multiselect',
          options: userList,
        },
        {
          label: 'Статус',
          field: 'status',
          type: 'multiselect',
          options: [
            {
              id: 0,
              name: 'Не выполнена',
            },
            {
              id: 1,
              name: 'Выполняется',
            },
            {
              id: 2,
              name: 'Выполнена',
            },
          ],
        },
      ]"
    >
      <template #nameSlot="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'TaskShow', params: { id: data.id } }"
        >
          {{ data.name }}
        </router-link>
      </template>
      <template #emplyeeList="{ data }">
        <template v-for="(employee, i) in data.employees" :key="i">
          {{ employee.surname }} {{ employee.name }} {{ employee.second_name }}
          <br />
        </template>
      </template>
      <template #twoDate="{ data }">
        {{ Str.date(data.start) }} <br />
        {{ Str.date(data.end) }}
      </template>
      <template #producerName="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'UserShow', params: { id: data.producer } }"
        >
          {{ data.producerName.surname }} {{ data.producerName.name }}
          {{ data.producerName.second_name }}
        </router-link>
      </template>
      <template #statusSlot="{ data }">
        <span v-if="data.status == 0" class="text-danger">Не выполнена</span>
        <span v-if="data.status == 1" class="text-warning">Выполняется</span>
        <span v-if="data.status == 2" class="text-success">Выполнена</span>
      </template>
    </GridView>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import GridView from "@/components/ui/GridView.vue";
import TaskService from "../services/TaskService";
import UserService from "@/modules/user/services/UserService";
import Str from "@/utils/Str";

export default {
  components: {
    BreadCrumb,
    GridView,
  },
  data() {
    return {
      Str: Str,
      userList: {},
      service: new TaskService({
        expand: ["producerName"],
      }),
      userService: new UserService(),
    };
  },
  async created() {
    this.userList = await this.userService.allList();
  },
};
</script>
